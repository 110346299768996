import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { login } from "../../networkRequests/auth";
import { toastError, toastSuccess, loading } from "../../utils/common";
import { useAddress } from "../../context/addressState";

export default function Login() {
  const history = useHistory();
  const { address, setAddress } = useAddress();
  const { modelState, setModelState } = useModal();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const _login = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        return toastError("Please enter email");
      }
      if (!password) {
        return toastError("Please enter password");
      }
      if (password.length < 6) {
        return toastError("Password must be greater than 6 words.");
      }
      setLoader(true);

      const res = await login(email, password);
      localStorage.setItem("token", res.data.token.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      toastSuccess("successfully logged in");
      history.push("/home");
      setModelState(modelType.NULL);
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    setEmail("");
    setPassword("");
  }, [modelState]);

  return (
    <Modal
      open={modelState == modelType.LOGIN ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);

        setAddress("");
      }}
    >
      {loader ? loading : ""}

      <div className="modal-content form-content login-wrap">
        <div className="session-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
          <div className="session-content login-form-wrap text-center card shadow-box">
            <div className="sec-heading-wrap flex-space-between">
              <h4 className="sec-title mb-0">Login to your account</h4>
              {/* <i className="fas fa-times" /> */}
            </div>
            <div className="login-session-flex">
              <div className="session-left">
                <div className="sec-content-wrap">
                  <form>
                    <div className="form-group text-left login-input mb-3">
                      <label>Email</label>
                      <input
                        name="name-input"
                        className="form-control"
                        type="email"
                        // defaultValue
                        placeholder="example@gmail.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group text-left login-input mb-3">
                      <label>Password</label>
                      <input
                        name="name-input"
                        className="form-control"
                        type="password"
                        //defaultValue
                        placeholder="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="page-link-text">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setModelState(modelType.FORGOT_PASSWORD);
                        }}
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <div className="form-group mb-3">
                      <a
                        className="btn btn-primary"
                        href="javascript:void(0);"
                        onClick={_login}
                      >
                        Login
                      </a>
                    </div>
                    <div className="form-group mb-0">
                      <p className="mb-0">
                        Dont have an account?
                        <a
                          className="text-primary ml-1 font-text-bold "
                          href="javascript:void(0);"
                          onClick={() => {
                            setModelState(modelType.SIGNUP);
                          }}
                        >
                          Create New
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div className="session-right">
                <img src="/assets/images/login-image.png" alt="login image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
