import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { signUp } from "../../networkRequests/auth";
import {
  toastError,
  toastSuccess,
  loading,
  validatePassword,
} from "../../utils/common";
import { useAddress } from "../../context/addressState";

export default function Signup(props) {
  let history = useHistory();
  const { address, setAddress } = useAddress();
  const { modelState, setModelState } = useModal();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [loader, setLoader] = useState(false);

  const _signUp = async (e) => {
    e.preventDefault();

    if (!name) {
      return toastError("Please enter name");
    }
    if (!email) {
      return toastError("Please enter your email");
    }
    const passwordError = validatePassword(password);
    if (passwordError.length) {
      return toastError(passwordError.join());
    }

    try {
      setLoader(true);
      const res = await signUp(name, email, password, lastName);
      toastSuccess(res.message);
      localStorage.setItem("token", res.data.accessToken.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setRefresh(!refresh);
      setModelState(modelType.NULL);
      setLoader(false);

      // window.location.reload();
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };
  useEffect(() => {
    history.push({ pathname: "/home" });
  }, [refresh]);

  useEffect(() => {
    setEmail("");
    setPassword("");
  }, [modelState]);

  return (
    <Modal
      open={modelState == modelType.SIGNUP ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);
        setAddress("");
      }}
      classNames="modal-dialog modal-xl form-size"
    >
      {loader ? loading : ""}

      <div className="modal-content form-content login-wrap">
        <div className="session-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
          <div className="session-content login-form-wrap text-center card shadow-box">
            <div className="sec-heading-wrap flex-space-between">
              <h4 className="sec-title mb-0">Create your account</h4>
              {/* <i className="fas fa-times" /> */}
            </div>
            <div className="login-session-flex">
              <div className="session-left">
                <div className="sec-content-wrap">
                  <form>
                    <div className="form-group text-left login-input mb-3">
                      <label>First Name</label>
                      <input
                        name="name-input"
                        className="form-control"
                        type="text"
                        //defaultValue
                        placeholder="example john"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group text-left login-input mb-3">
                      <label>Last Name</label>
                      <input
                        name="name-input"
                        className="form-control"
                        type="text"
                        value={lastName}
                        placeholder="example lewis"
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group text-left login-input mb-3">
                      <label>Email</label>
                      <input
                        name="name-input"
                        className="form-control"
                        type="email"
                        //defaultValue
                        placeholder="example@gmail.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group text-left login-input mb-3">
                      <label>Password</label>
                      <input
                        name="name-input"
                        className="form-control"
                        type="password"
                        value={password}
                        // defaultValue
                        placeholder="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group mb-3 mt-4">
                      <a
                        className="btn btn-primary"
                        onClick={_signUp}
                        href="javascript:void(0);"
                      >
                        Sign Up
                      </a>
                    </div>
                    <div className="form-group mb-0">
                      <p className="mb-0">
                        Dont have an account?
                        <a
                          className="text-primary ml-1 font-text-bold"
                          href="javascript:void(0);"
                          onClick={() => {
                            setModelState(modelType.LOGIN);
                          }}
                        >
                          Login
                        </a>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div className="session-right">
                <img src="assets/images/login-image.png" alt="login image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
