import React, { useState, useEffect } from "react";
import Home from "./Home";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { forgotPasswordRequest } from "../../networkRequests/auth";
import { signUp } from "../../networkRequests/auth";

import { login } from "../../networkRequests/auth";
import {
  toastError,
  toastSuccess,
  loading,
  validatePassword,
} from "../../utils/common";

export default function Login() {
  const [model, setModel] = useState(true);
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const _login = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        return toastError("Please enter email");
      }
      if (!password) {
        return toastError("Please enter password");
      }
      if (password.length < 6) {
        return toastError("Password must be greater than 6 words.");
      }
      setLoader(true);

      const res = await login(email, password);
      localStorage.setItem("token", res.data.token.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      toastSuccess("successfully logged in");
      history.push("/home");
      window.location.reload();
      // setModelState(modelType.NULL);
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _forgotPasswordRequest = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        return toastError("Please enter email");
      }
      setLoader(true);

      const res = await forgotPasswordRequest(email);
      toastSuccess(res.message);
      setModel(true);
      setEmail("");
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const _signUp = async (e) => {
    e.preventDefault();

    if (!name) {
      return toastError("Please enter name");
    }
    if (!email) {
      return toastError("Please enter your email");
    }
    const passwordError = validatePassword(password);
    if (passwordError.length) {
      return toastError(passwordError.join());
    }

    try {
      setLoader(true);
      const res = await signUp(name, email, password, lastName);
      toastSuccess(res.message);
      localStorage.setItem("token", res.data.accessToken.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      history.push("/home");
      window.location.reload();
      // window.location.reload();
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  //  useEffect(() => {
  //    setEmail("");
  //    setPassword("");
  //  }, [modelState]);
  return (
    <div>
      <Modal
        styles={{
          closeButton: {
            color: "red",
            display: "none",
          },
        }}
        open={model == true ? true : false}
        onClose={() => {}}
      >
        {loader && loading}

        <div className="modal-content form-content login-wrap">
          <div className="session-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
            <div className="session-content login-form-wrap text-center card shadow-box">
              <div className="sec-heading-wrap flex-space-between">
                <h4 className="sec-title mb-0">Login to your account</h4>
                {/* <i className="fas fa-times" /> */}
              </div>
              <div className="login-session-flex">
                <div className="session-left">
                  <div className="sec-content-wrap">
                    <form>
                      <div className="form-group text-left login-input mb-3">
                        <label>Email</label>
                        <input
                          name="name-input"
                          className="form-control"
                          type="email"
                          // defaultValue
                          placeholder="example@gmail.com"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group text-left login-input mb-3">
                        <label>Password</label>
                        <input
                          name="name-input"
                          className="form-control"
                          type="password"
                          //defaultValue
                          placeholder="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className="page-link-text">
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            setModel(modelType.FORGOT_PASSWORD);
                          }}
                        >
                          Forgot Password?
                        </a>
                      </div>
                      <div className="form-group mb-3">
                        <a
                          className="btn btn-primary"
                          href="javascript:void(0);"
                          onClick={_login}
                        >
                          Login
                        </a>
                      </div>
                      <div className="form-group mb-0">
                        <p className="mb-0">
                          Dont have an account?
                          <a
                            className="text-primary ml-1 font-text-bold "
                            href="javascript:void(0);"
                            onClick={() => {
                              setModel(modelType.SIGNUP);
                            }}
                          >
                            Create New
                          </a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="session-right">
                  <img src="/assets/images/login-image.png" alt="login image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={model == modelType.FORGOT_PASSWORD ? true : false}
        onClose={() => {
          setModel(true);
        }}
        styles={{
          closeButton: {
            display: "none",
          },
        }}
      >
        {loader ? loading : ""}

        <div className="session-wrap content-box-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
          <div className="session-content contact-form-wrap text-center card shadow-box">
            <div className="sec-heading-wrap flex-space-between">
              <h4 className="sec-title">Forgot Password?</h4>
              {/* <i className="fas fa-times" /> */}
            </div>
            <div className="sec-content-wrap">
              <form>
                <p className="sec-content">
                  We deliver your products safely to your home in a reasonable
                  time. We give you complete reliable delivery for your company.
                  We will take full responsibility of the deliveries.
                </p>
                <div className="form-group text-left login-input">
                  <label>Enter Registered Email Address</label>
                  <input
                    name="name-input"
                    className="form-control"
                    type="email"
                    //defaultValue
                    placeholder="example@gmail.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="btn-wrap text-align-center mt-4">
                  <a
                    className="btn btn-primary"
                    href="javascript:void(0);"
                    onClick={_forgotPasswordRequest}
                  >
                    Submit
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={model == modelType.SIGNUP ? true : false}
        onClose={() => {
          // setModel(true);
        }}
        styles={{
          closeButton: {
            display: "none",
          },
        }}
        classNames="modal-dialog modal-xl form-size"
      >
        {loader ? loading : ""}

        <div className="modal-content form-content login-wrap">
          <div className="session-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
            <div className="session-content login-form-wrap text-center card shadow-box">
              <div className="sec-heading-wrap flex-space-between">
                <h4 className="sec-title mb-0">Create your account</h4>
                {/* <i className="fas fa-times" /> */}
              </div>
              <div className="login-session-flex">
                <div className="session-left">
                  <div className="sec-content-wrap">
                    <form>
                      <div className="form-group text-left login-input mb-3">
                        <label>First Name</label>
                        <input
                          name="name-input"
                          className="form-control"
                          type="text"
                          //defaultValue
                          placeholder="example john"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group text-left login-input mb-3">
                        <label>Last Name</label>
                        <input
                          name="name-input"
                          className="form-control"
                          type="text"
                          value={lastName}
                          placeholder="example lewis"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group text-left login-input mb-3">
                        <label>Email</label>
                        <input
                          name="name-input"
                          className="form-control"
                          type="email"
                          //defaultValue
                          placeholder="example@gmail.com"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-group text-left login-input mb-3">
                        <label>Password</label>
                        <input
                          name="name-input"
                          className="form-control"
                          type="password"
                          value={password}
                          // defaultValue
                          placeholder="password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>

                      <div className="form-group mb-3 mt-4">
                        <a
                          className="btn btn-primary"
                          onClick={_signUp}
                          href="javascript:void(0);"
                        >
                          Sign Up
                        </a>
                      </div>
                      <div className="form-group mb-0">
                        <p className="mb-0">
                          Dont have an account?
                          <a
                            className="text-primary ml-1 font-text-bold"
                            href="javascript:void(0);"
                            onClick={() => {
                              setModel(true);
                            }}
                          >
                            Login
                          </a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="session-right">
                  <img src="assets/images/login-image.png" alt="login image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
