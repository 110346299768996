import React, { Suspense, useState } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import { ModalContext } from "./context/modalState";
import { AddressContext } from "./context/addressState";
import NonAuthLayout from "./containers/NonAuthLayout";
import AuthLayout from "./containers/AuthLayout";
import PaymentSuccess from "./views/pages/PaymentSuccess";
import DriverResetPassword from "./views/pages/DriverResetPassword";
import Login from "./views/pages/Login";

function App() {
  const [modelState, setModelState] = useState(false);
  const changeModelState = (data) => {
    setModelState(data);
  };

  const [address, setAddress] = useState(false);
  const changeAddressId = (data) => {
    setAddress(data);
  };
  let auth = localStorage.token;

  return (
    <BrowserRouter>
      <Suspense fallback={"loading"}>
        <Switch>
        <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/createOrder/payment/success"
            component={PaymentSuccess}
          />

          <Route
            exact
            path="/createOrder/payment/success"
            component={PaymentSuccess}
          />
          <Route
            exact
            path="/resetpassword/driver/:token"
            component={DriverResetPassword}
          />
          <ModalContext.Provider
            value={{ modelState, setModelState: changeModelState }}
          >
            
            <AddressContext.Provider
              value={{ address, setAddress: changeAddressId }}
            >
              {auth && (
                <Route path="/" render={(props) => <AuthLayout {...props} />} />
              )}

              {!auth && (
                <Route
                  path="/"
                  render={(props) => <NonAuthLayout {...props} />}
                />
              )}

              {/* {!auth && (
                <Route
                  render={() => {
                    history.push({ pathname: "/index" });
                    //  history.go("/index");
                  }}
                />
              )} */}

              {/* <Route component={Index} /> */}
            </AddressContext.Provider>
          </ModalContext.Provider>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
