import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { addAddress } from "../../networkRequests/profile";
import { toastError, toastSuccess, loading } from "../../utils/common";

export default function AddAddress(props) {
  const { modelState, setModelState } = useModal();
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [loader, setLoader] = useState(false);
  const [lotNumber, setLotNumber] = useState("");
  const [fullAddress, setFullAddress] = useState("");
  console.log(props.addressType);

  const _addAddress = async (e) => {
    e.preventDefault();

    if (!title) {
      return toastError("Please enter title");
    }
    if (!longitude || !latitude) {
      return toastError("Please select address");
    }
    if (!address) {
      return toastError("Please enter full address");
    }

    try {
      setLoader(true);

      const res = await addAddress(
        title,
        address,
        longitude,
        latitude,
        lotNumber,
        fullAddress
      );
      const _id = new Date().getTime();
      let data = {
        _id,
        title,
        address,
        longitude,
        latitude,
        lotNumber,
        fullAddress,
      };

      if (props.addressType) {
        console.log(props.addressType);
        if (props.addressType === "pickup") {
          // props.setPickUpAddress(res.data.addedAddress._id);
          props.setPickUpAddress(data);
        } else {
          console.log("drop", data._id);
          // props.setDropAddressId(res.data.addedAddress._id);
          props.setDropAddress(data);
        }
        props.pushAddress(data);
      }
      setModelState(modelType.REFRESH_ADDRESS);
      toastSuccess("Address successfully added");
      setAddress("");
      setLatitude("");
      setLongitude("");
      setTitle("");
      setFullAddress("");
      setLoader(false);
    } catch (error) {
      toastError(error.data.message);
      console.log(error.data.message);
      setLoader(false);
    }
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(value);
    const coordinates = await getLatLng(results[0]);
    setLongitude(coordinates.lng);
    setLatitude(coordinates.lat);

    // setAddress(value);
    // setCoordinates(ll);
  };

  return (
    <Modal
      open={modelState == modelType.ADD_ADDRESS ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);
      }}
    >
      {loader ? loading : ""}
      <div className="session-wrap content-box-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
        <div className="session-content contact-form-wrap text-center card shadow-box">
          <div className="sec-heading-wrap flex-space-between">
            <h4 className="sec-title mb-0">Add Address</h4>
            {/* <i className="fas fa-times" /> */}
          </div>
          <div className="sec-content-wrap">
            <form className="form-wrap">
              <div className="form-group">
                <label>Title/Name</label>
                <input
                  required
                  name="name-input"
                  className="form-control"
                  type="text"
                  //defaultValue
                  placeholder="Name"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>Full Address</label>
                <input
                  required
                  name="name-input"
                  className="form-control"
                  type="text"
                  //defaultValue
                  placeholder="Name"
                  onChange={(e) => {
                    setFullAddress(e.target.value);
                  }}
                />
              </div>

              <div className="form-group">
                <label>Address</label>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "search places ...",
                          className: "form-control",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={suggestion.placeId}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>

              <div className="form-group">
                <label>Lot Number</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="text"
                  //defaultValue
                  placeholder="123ABC"
                  onChange={(e) => {
                    setLotNumber(e.target.value);
                  }}
                />
              </div>

              <div className="btn-wrap text-align-center mt-4">
                <a
                  className="btn btn-primary"
                  href="javascript:void(0);"
                  onClick={_addAddress}
                >
                  Add Address
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
