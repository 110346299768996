import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import { useModal } from "../context/modalState";
import { modelType } from "../utils/appConstants";

export default function LoginHeader() {
  let history = useHistory();
  const { modelState, setModelState } = useModal();
  const [refresh, setRefresh] = useState(false);
  const user = JSON.parse(localStorage.user);

  useEffect(() => {
    // history.push("/");
  }, [refresh]);

  return (
    <header id="header-sec" className="header-v1 header-v2 header-transparent">
      <div className="header-wrap header-bg-color">
        <div className="container-fluid px-0">
          <div className="custom-container">
            <nav className="navbar navbar-expand-lg navbar-dark theme-navbar px-0">
              <NavLink className="navbar-brand" to="/">
                <img
                  src="/assets/images/logo.png"
                  width={100}
                  height={56}
                  alt="header-logo"
                />
              </NavLink>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#main-header"
                aria-controls="main-header"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="navbar-collapse collapse" id="main-header">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/home">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/createOrder">
                      Create Order
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/myOrders">
                      My Orders
                    </NavLink>
                  </li>

                  <li className="nav-item mt-lg-0 mt-3 center-align">
                    <div className="dropdown">
                      <button
                        style={{ textTransform: "none" }}
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {user.name} {user.lastName}&nbsp;
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          onClick={() => {
                            setModelState(modelType.USER_DETAIL);
                          }}
                        >
                          Personal Details
                        </a>
                        <NavLink
                          className="dropdown-item"
                          href="javascript:void(0);"
                          to="/addresses"
                        >
                          My Addresses
                        </NavLink>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          onClick={() => {
                            setModelState(modelType.CHANGE_PASSWORD);
                          }}
                        >
                          Change Password
                        </a>
                        <a
                          className="dropdown-item"
                          href="javascript:void(0);"
                          onClick={() => {
                            localStorage.clear();
                            //setRefresh(!refresh);
                            window.location.reload();
                          }}
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <div id="header-sticky" className="sticky-header">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="logo-wrap align-self-center">
              <NavLink to="/home" className="logo">
                <img
                  src="assets/images/sos-logo-white.png"
                  alt="sticky-logo"
                  className="img-responsive"
                  width={100}
                  height={56}
                />
              </NavLink>
            </div>
            <div className="align-self-center">
              <div className="header-menu pull-left">
                <nav id="sticky-nav" className="main-navigation theme-navbar">
                  <div className="primary-menu">
                    <ul className="navbar-nav mr-n3 mx-lg-auto px-0 flex-row">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/home">
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/createOrder">
                          Create Order
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/myOrders">
                          My Orders
                        </NavLink>
                      </li>

                      <li className="nav-item mt-lg-0 mt-3 center-align">
                        <div className="dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {user.name}&nbsp;
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => {
                                setModelState(modelType.USER_DETAIL);
                              }}
                            >
                              Personal Details
                            </a>
                            <NavLink
                              className="dropdown-item"
                              href="javascript:void(0);"
                              to="/addresses"
                            >
                              My Addresses
                            </NavLink>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => {
                                setModelState(modelType.CHANGE_PASSWORD);
                              }}
                            >
                              Change Password
                            </a>
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              onClick={() => {
                                localStorage.clear();
                                //setRefresh(!refresh);
                                window.location.reload();
                              }}
                            >
                              Logout
                            </a>
                          </div>
                        </div>
                      </li>

                       <li className="nav-item mt-lg-0 mt-3 center-align">
                        <a
                          className="btn btn-white-primary px-lg-3 px-3"
                          href="javascript:void(0);"
                        >
                          Login
                        </a>
                      </li> 
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
}
