import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <footer id="footer-wrap" className="footer-section footer-spacing">
      <div className="footer-wrap">
        <div className="footer-content-wrap footer-bottom-wrap footer-top-bar">
          <div className="custom-container">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-4 grid-item-space footer-top-space mb-lg-0 mb-4">
                <div className="footer-sec footer-sec-1">
                  <div className="footer-widget-area mb-7">
                    <a href="index.html" className="footer-logo">
                      <img
                        src="assets/images/sos-logo-white.png"
                        width={100}
                        height={56}
                        alt="Footer Logo"
                      />
                    </a>
                    <p className="sec-content">
                      Copyrights@ 2021. All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
              {/*Footer social icon close */}
              <div className="col-12 col-sm-12 col-lg-4 footer-top-space footer-menu-link grid-item-space mb-md-0 mb-3">
                <div className="footer-sec footer-sec-3 ml-lg-4">
                  <div className="footer-widget-area">
                    <div className="footer-menu-list">
                      <div className="info-content">
                        <a className="mb-2" href="#">
                          Home
                        </a>

                        <a className="mb-2" href="#aboutus-div">
                          About Us
                        </a>

                        <a className="mb-2" href="#contactus-div">
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-lg-4 footer-top-space align-self-center grid-item-space mb-md-0 mb-3">
                <form className="subscribe-form">
                  <div className="form-group mb-lg-0 ">
                    <input
                      name="name-input"
                      className="form-control search-input"
                      type="search"
                      placeholder="example@gmail.com"
                    />
                  </div>
                  <div className="subscribe-btn">
                    <a className="btn btn-primary" href="javascript:void(0);">
                      Subscribe
                    </a>
                  </div>
                </form>
              </div>
            </div>
            {/* <div className="footer-content-copyright flex-space-between">
              <div className="copyright-text">
                <p className="sec-content mb-0">
                  Copyrights@ 2021. All Rights Reserved.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
