import React, { Suspense } from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { loading } from "../utils/common";
import routes from "../nonAuthRoutes";
import Header from "./NonAuthHeader";

import Footer from "./Footer";
import Login from "../views/modals/Login";
import Signup from "../views/modals/Signup";
import ForgotPassword from "../views/modals/ForgotPassword";

export default function NonAuthLayout(props) {
  return (
    <div className="wrapper">
      <Header />
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                )
              );
            })}
            <Redirect from="/" to="/index" />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <Footer />
      <Login />
      <Signup />
      <ForgotPassword />
    </div>
  );
}
