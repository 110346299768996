import axios from "axios";
import { baseUrl } from "../utils/appConstants";
import qs from "qs";

export const forgotPassword = async (password, resetPasswordToken) => {
  try {
    const data = {
      password,
    };

    const header = {
      headers: {
        authorization: resetPasswordToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let res = await axios
      .post(`${baseUrl}/auth/resetPassword`, qs.stringify(data), header, {
        crossDomain: true,
      })
      .catch((error) => {
        throw error;
      });
    return res;
  } catch (error) {
    throw error;
  }
};

export const verifyResetPasswordToken = async (resetPasswordToken) => {
  try {
    const header = {
      headers: {
        authorization: resetPasswordToken,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    let res = await axios
      .get(`${baseUrl}/auth/verifyResetPasswordToken`, header, {
        crossDomain: true,
      })
      .catch((error) => {
        throw error;
      });
    return res;
  } catch (error) {
    throw error;
  }
};
