import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useModal } from "../../context/modalState";
import { useHistory } from "react-router-dom";

import { modelType } from "../../utils/appConstants";
import { useAddress } from "../../context/addressState";

export default function Test(props) {
 
  const { modelState, setModelState } = useModal();
  const { address, setAddress } = useAddress();
  const [auth, setAuth] = useState("");

  const history = useHistory();

  const settings = {
    slidesToShow: 2,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  const handlePackageOrder = async () => {
    console.log(auth);
    if (auth) {
      console.log("auth");
      history.push("/createOrder");
    } else {
      console.log("1111helllo");
      setAddress("openCreateOrder");
      setModelState(modelType.LOGIN);
    }
  };

  useEffect(() => {
    setAuth(localStorage.getItem("token"));
  }, []);

  return (
    <div>
      <section className="banner-section">
        <div className="banner-slider">
          <div className="banner-image-item banner-wrap">
            <div className="banner-overlay">
              <div className="custom-container">
                <div className="row">
                  <div
                    className="col-12 col-md-10 col-lg-6 align-self-center  wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    <div className="banner-sec-wrap">
                      <div className="banner-sec-content">
                        <h6 className="banner-sec-title">
                          A trusted &amp; fastest provider
                          <span>of courier services.</span>
                        </h6>
                        <p className="sec-content">
                          We deliver your products safely to your home in a
                          reasonable time. We give you complete reliable
                          delivery for your company. We will take full
                          responsibility of the deliveries.
                        </p>

                        <a
                          className="btn btn-primary py-3 mb-2 btn-banner-1"
                          href="javascript:void(0);"
                          onClick={handlePackageOrder}
                        >
                          <div className="flex-content">
                            Order Packages
                            <i className="fas fa-arrow-right ml-3" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-10 col-lg-6 align-self-center  wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.6s"
                  >
                    <div className="image-wrap">
                      <img
                        src="assets/images/banner-image1.png"
                        alt="banner image"
                      />
                    </div>
                  </div>
                </div>
                <div id="service-div" className="banner-service-card-wrap">
                  <div className="sec-heading-title">
                    <h6 className="sec-title">
                      A trusted &amp; fastest provider of{" "}
                      <span>courier services.</span>
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-lg-0 mb-5 col-align">
                      <div className="banner-sec-wrap">
                        <div className="card-box">
                          <div className="image-wrap">
                            <img src="assets/images/banner-box-1.png" />
                          </div>
                          <h6 className="sec-title">Any Chosen Material</h6>
                          <p className="sec-content">
                            We give you complete reliable delivery for your
                            company. We will take full responsibility of the
                            deliveries.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-lg-0 mb-5 col-align">
                      <div className="banner-sec-wrap">
                        <div className="card-box">
                          <div className="image-wrap">
                            <img src="assets/images/banner-box-2.png" />
                          </div>
                          <h6 className="sec-title">Statewide Delivery</h6>
                          <p className="sec-content">
                            Offering home delivery around the city, where your
                            products will be at your doorstep within 48-72
                            hours.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-align">
                      <div className="banner-sec-wrap">
                        <div className="card-box">
                          <div className="image-wrap">
                            <img src="assets/images/banner-box-3.png" />
                          </div>
                          <h6 className="sec-title">Any Chosen Material</h6>
                          <p className="sec-content">
                            Offering home delivery around the city, where your
                            products will be at your doorstep within 48-72
                            hours.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="process-div" className="content py-0">
        {/* delivery-process-wrap */}
        <section className="delivery-process-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-6 mx-auto">
                <div className="sec-heading-title">
                  <h4 className="sec-title">How we make it possible </h4>
                  <p className="sec-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc non tellus nullam ultrices netus mauris, phasellus.
                  </p>
                </div>
              </div>
            </div>
            <div className="delivery-process-content">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="card-process">
                    <div className="image-wrap">
                      <img src="assets/images/calendar.png" alt="calendar" />
                    </div>
                    <h6 className="sec-title">Other Verification</h6>
                    <p className="sec-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Gravida vitae fermentum
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="card-process">
                    <div className="image-wrap">
                      <img src="assets/images/packaging.png" alt="calendar" />
                    </div>
                    <h6 className="sec-title">Packaging</h6>
                    <p className="sec-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Gravida vitae fermentum
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="card-process">
                    <div className="image-wrap">
                      <img src="assets/images/parcel.png" alt="calendar" />
                    </div>
                    <h6 className="sec-title">Verify Parcel</h6>
                    <p className="sec-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Gravida vitae fermentum
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="card-process">
                    <div className="image-wrap">
                      <img src="assets/images/airplane.png" alt="calendar" />
                    </div>
                    <h6 className="sec-title">Delivery</h6>
                    <p className="sec-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Gravida vitae fermentum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* delivery-process-wrap close */}
        {/* home-sec-content */}
        <section className="home-sec-content">
          <div className="custom-container">
            {/* awards-sec-wrap */}
            <div id="aboutus-div" className="awards-sec-wrap">
              <div className="awards-sec-content">
                <div className="awards-flex">
                  <div className="counter-content text-center">
                    <div className="icon-circle mb-2">
                      <img
                        src="assets/images/award-cup.png"
                        alt="awards image"
                      />
                    </div>
                    <div className="text-foreground">
                      <div className="number-wrap">
                        <span className="number-count font-weight-bold">
                          26+
                        </span>
                      </div>
                      <div className="text-wrap">
                        <span className="text-title font-w-600">
                          Awards won
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="counter-content text-center">
                    <div className="icon-circle mb-2">
                      <img
                        src="assets/images/award-status.png"
                        alt="awards image"
                      />
                    </div>
                    <div className="text-foreground">
                      <div className="number-wrap">
                        <span className="number-count font-weight-bold">
                          65+
                        </span>
                      </div>
                      <div className="text-wrap">
                        <span className="text-title font-w-600">
                          States covered
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="counter-content text-center">
                    <div className="icon-circle mb-2">
                      <img
                        src="assets/images/awards-client.png"
                        alt="awards image"
                      />
                    </div>
                    <div className="text-foreground">
                      <div className="number-wrap">
                        <span className="number-count font-weight-bold">
                          689K+
                        </span>
                      </div>
                      <div className="text-wrap">
                        <span className="text-title font-w-600">
                          Happy clients
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="counter-content text-center">
                    <div className="icon-circle mb-2">
                      <img
                        src="assets/images/awardeliver.png"
                        alt="awards image"
                      />
                    </div>
                    <div className="text-foreground">
                      <div className="number-wrap">
                        <span className="number-count font-weight-bold">
                          130M+
                        </span>
                      </div>
                      <div className="text-wrap">
                        <span className="text-title font-w-600">
                          Goods delivered
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="counter-content text-center">
                    <div className="icon-circle mb-2">
                      <img
                        src="assets/images/awards-hour.png"
                        alt="awards image"
                      />
                    </div>
                    <div className="text-foreground">
                      <div className="number-wrap">
                        <span className="number-count font-weight-bold">
                          130M+
                        </span>
                      </div>
                      <div className="text-wrap">
                        <span className="text-title font-w-600">
                          Business hours
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* awards-sec-wrap close */}
            {/* testimonial-wrap */}
            <div className="testimonial-wrap">
              <div className="sec-heading-title">
                <h6 className="sec-title">
                  What our client’s say about us <span>Our Reviews</span>
                </h6>
              </div>
              <Slider {...settings} className="testimonial-slider-wrap">
                <div className="testimonial-card">
                  <div className="testimonial-content">
                    <p className="sec-content">
                      I purchased a phone from an e-commerce site, and this
                      courier service provider assisted me in getting it
                      delivered to my home. I received my phone within one day,
                      and I was really satisfied with their service when I
                      received it. They are really quick and dependable.
                    </p>
                    <div className="testimonial-heading">
                      <div className="star-wrap">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <div className="flex-content-center">
                        <div className="testimonial-title">
                          <h6 className="sec-title mb-0">Yves Tanguy</h6>
                          <p className="sec-content">Chief Executive, DLF</p>
                        </div>
                        <div className="image-wrap">
                          <img
                            src="assets/images/testi-img-thumbnail.png"
                            alt="testimonial img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-card">
                  <div className="testimonial-content">
                    <p className="sec-content">
                      I purchased a phone from an e-commerce site, and this
                      courier service provider assisted me in getting it
                      delivered to my home. I received my phone within one day,
                      and I was really satisfied with their service when I
                      received it. They are really quick and dependable.
                    </p>
                    <div className="testimonial-heading">
                      <div className="star-wrap">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <div className="flex-content-center">
                        <div className="testimonial-title">
                          <h6 className="sec-title mb-0">Yves Tanguy</h6>
                          <p className="sec-content">Chief Executive, DLF</p>
                        </div>
                        <div className="image-wrap">
                          <img
                            src="assets/images/testi-img-thumbnail.png"
                            alt="testimonial img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-card">
                  <div className="testimonial-content">
                    <p className="sec-content">
                      I purchased a phone from an e-commerce site, and this
                      courier service provider assisted me in getting it
                      delivered to my home. I received my phone within one day,
                      and I was really satisfied with their service when I
                      received it. They are really quick and dependable.
                    </p>
                    <div className="testimonial-heading">
                      <div className="star-wrap">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <div className="flex-content-center">
                        <div className="testimonial-title">
                          <h6 className="sec-title mb-0">Yves Tanguy</h6>
                          <p className="sec-content">Chief Executive, DLF</p>
                        </div>
                        <div className="image-wrap">
                          <img
                            src="assets/images/testi-img-thumbnail.png"
                            alt="testimonial img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            {/* testimonial-wrap close */}
            {/* location-wrap */}
            <div id="contactus-div" className="location-wrap">
              <div className="sec-heading-title inline-title">
                <h6 className="sec-title">
                  Find Us <span>Access us easily</span>
                </h6>
              </div>
              <div className="location-content">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-6 col-lg-6">
                    <div className="image-wrap">
                      <img
                        src="assets/images/location.png"
                        alt="location img"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6">
                    <div className="get-in-touch-wrap">
                      <h6 className="sec-title">Get in touch!</h6>
                      <div className="address-wrap">
                        <p>
                          <i className="fas fa-map-marker-alt" />
                          <span>2277 Lorem Ave, San Diego, CA 22553</span>
                        </p>
                        <p>
                          <i className="far fa-clock" />
                          <span className="date-time">
                            Monday - Friday: 10 am - 10pm <br />
                            Sunday: 11 am - 9pm
                          </span>
                        </p>
                        <p>
                          <i className="far fa-envelope" />
                          <span>info@stayonsite.com.au</span>
                        </p>
                      </div>
                      <div className="social-icon-wrap">
                        <div className="icon-wrap">
                          <a href="https://twitter.com/">
                            <i className="fab fa-twitter" />
                          </a>
                        </div>
                        <div className="icon-wrap">
                          <a href="https://www.facebook.com/stayonsite">
                            <i className="fab fa-facebook-square" />
                          </a>
                        </div>
                        <div className="icon-wrap">
                          <a href="https://www.instagram.com/stayonsite/">
                            <i className="fab fa-instagram" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-wrap">
                  <a className="btn btn-primary" href="javascript:void(0);">
                    <div className="flex-content">
                      <i className="fas fa-phone-alt" />
                      Call us for enquiries 1300 148 821
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {/* location-wrap close */}
          </div>
        </section>
      </div>
    </div>
  );
}
