import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { changePassword } from "../../networkRequests/profile";
import {
  toastError,
  toastSuccess,
  loading,
  validatePassword,
} from "../../utils/common";

export default function ChangePassword() {
  const { modelState, setModelState } = useModal();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConformPassword] = useState();
  const [loader, setLoader] = useState(false);

  const _changePassword = async (e) => {
    e.preventDefault();
    if (!oldPassword || !confirmPassword) {
      return toastError("Please fill all fields");
    }

    if (newPassword != confirmPassword) {
      return toastError("New and confirm Password does not match");
    }
    if (oldPassword == newPassword) {
      return toastError("Old and new password is same");
    }
    const passwordError = validatePassword(newPassword);
    if (passwordError.length) {
      return toastError(passwordError.join());
    }
    try {
      setLoader(true);
      const res = await changePassword(oldPassword, newPassword);
      toastSuccess(res.message);
      setModelState(modelType.NULL);
      setConformPassword("");
      setNewPassword("");
      setOldPassword("");
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    setConformPassword("");
    setNewPassword("");
    setOldPassword("");
  }, [modelState]);

  return (
    <Modal
      open={modelState == modelType.CHANGE_PASSWORD ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);
      }}
    >
      {loader ? loading : ""}

      <div className="session-wrap content-box-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
        <div className="session-content contact-form-wrap text-center card shadow-box">
          <div className="sec-heading-wrap flex-space-between">
            <h4 className="sec-title mb-0">Change Password</h4>
            {/* <i className="fas fa-times" /> */}
          </div>
          <div className="sec-content-wrap">
            <form className="form-wrap">
              <div className="form-group">
                <label>Old Password</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="password"
                  //defaultValue
                  placeholder="old password"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="password"
                  //defaultValue
                  placeholder="new password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="password"
                  //defaultValue
                  placeholder="confirm new password"
                  onChange={(e) => {
                    setConformPassword(e.target.value);
                  }}
                />
              </div>
              <div className="btn-wrap text-align-center mt-4">
                <a
                  className="btn btn-primary"
                  href="javascript:void(0);"
                  onClick={_changePassword}
                >
                  Save Changes
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
