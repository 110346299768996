import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { loading } from "../utils/common";
import routes from "../authRoutes";
import Header from "./AuthHeader";
import Footer from "./Footer";
import AddAddress from "../views/modals/AddAddress";
import ChangePassword from "../views/modals/ChangePassword";
import EditAddress from "../views/modals/EditAddress";
import UserDetail from "../views/modals/UserDetail";
import { useAddress } from "../context/addressState";

export default function AuthLayout() {
  const history = useHistory();
  const { address, setAddress } = useAddress();

  useEffect(() => {
    if (address === "openCreateOrder") {
      history.push("/createOrder");
      setAddress("");
    }
  }, []);

  return (
    <div className="wrapper">
      <Header />
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => <route.component {...props} />}
                />
              )
            );
          })}
          <Redirect from="/" to="/home" />
        </Switch>
      </Suspense>
      <Footer />
      {/* <AddAddress /> */}
      <ChangePassword />
      <EditAddress />
      <UserDetail />
    </div>
  );
}
