import qs from "qs";
import { makePostRequest, makeGetRequest } from "./axiosBase";

export const createOrder = async (data) => {
  delete data.pickupAddress.isDefault;
  delete data.dropAddress.isDefault;
  delete data.pickupAddress._id;
  delete data.dropAddress._id;
  delete data.dropAddress.drop;
  delete data.pickupAddress.drop;
  try {
    const config = {
      skipAuth: false,
    };

    let res = await makePostRequest(
      "/web/user/package",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPackage = async () => {
  try {
    //   const params = {
    //     addressId,
    //   };

    let res = await makeGetRequest("/web/user/package/list");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPackageDetail = async (packageId) => {
  try {
    const params = {
      packageId,
    };

    let res = await makeGetRequest("/web/user/package", params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const payment = async (data) => {
  try {
    const config = {
      skipAuth: false,
    };

    let res = await makePostRequest(
      "/web/user/package/payment",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDistance = async (data) => {
  try {
    let res = await makeGetRequest("/admin/package/getDistane", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentIntent = async (data) => {
  try {
    const config = {
      skipAuth: false,
    };

    let res = await makePostRequest(
      "/web/user/package/create-payment-intent",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const conformPayment = async (paymentIntent) => {
  const data = { paymentIntent };
  try {
    const config = {
      skipAuth: false,
    };

    let res = await makeGetRequest(
      "/web/user/package/payment-intent",
      data,
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const priceRangeAmount = async (userId, distance, type) => {
  try {
    console.log(userId, type);
    const params = { userId, distance, type };
    let res = await makeGetRequest("/admin/priceRange/price", params);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
