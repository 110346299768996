import qs from "qs";
import { makePostRequest, makeGetRequest } from "./axiosBase";

export const signUp = async (name, email, password, lastName) => {
  try {
    const data = {
      name,
      email,
      password,
      lastName,
      deviceType: "Web",
      deviceToken: "testdata",
      userType: "User",
    };
    const config = { skipAuth: true };

    let res = await makePostRequest(
      "/auth/signup",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const data = {
      email,
      password,
      deviceType: "Web",
      deviceToken: "testdata",
      userType: "User",
    };
    const config = { skipAuth: true };

    let res = await makePostRequest("/auth/login", qs.stringify(data), config, {
      crossDomain: true,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPasswordRequest = async (email, password) => {
  try {
    const data = {
      email,
    };
    const config = { skipAuth: true };

    let res = await makePostRequest(
      "/auth/forgotPassword",
      qs.stringify(data),
      config,
      {
        crossDomain: true,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const veriftInviteToken = async (token) => {
  try {
    const params = {
      token,
    };

    let res = await makeGetRequest("/auth/verifyInviteToken", params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const signupSpecialUser = async (
  name,
  email,
  password,
  tokenId,
  companyId,
  priceRangeId
) => {
  try {
    const data = {
      name,
      email,
      password,
      deviceType: "Web",
      deviceToken: "testdata",
      tokenId,
      userType: "User",
      companyId,
      priceRangeId,
    };
    const config = { skipAuth: true };

    let res = await makePostRequest(
      "/auth/signup/specialUser",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};
