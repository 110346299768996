import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { forgotPasswordRequest } from "../../networkRequests/auth";
import { toastError, toastSuccess, loading } from "../../utils/common";

export default function ForgotPassword() {
  const { modelState, setModelState } = useModal();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const _forgotPasswordRequest = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        return toastError("Please enter email");
      }
      setLoader(true);

      const res = await forgotPasswordRequest(email);
      toastSuccess(res.message);
      setModelState(modelType.NULL);
      setEmail("");
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  return (
    <Modal
      open={modelState == modelType.FORGOT_PASSWORD ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);
      }}
    >
      {loader ? loading : ""}

      <div className="session-wrap content-box-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
        <div className="session-content contact-form-wrap text-center card shadow-box">
          <div className="sec-heading-wrap flex-space-between">
            <h4 className="sec-title">Forgot Password?</h4>
            {/* <i className="fas fa-times" /> */}
          </div>
          <div className="sec-content-wrap">
            <form>
              <p className="sec-content">
                We deliver your products safely to your home in a reasonable
                time. We give you complete reliable delivery for your company.
                We will take full responsibility of the deliveries.
              </p>
              <div className="form-group text-left login-input">
                <label>Enter Registered Email Address</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="email"
                  //defaultValue
                  placeholder="example@gmail.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="btn-wrap text-align-center mt-4">
                <a
                  className="btn btn-primary"
                  href="javascript:void(0);"
                  onClick={_forgotPasswordRequest}
                >
                  Submit
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
