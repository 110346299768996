import React, { useEffect } from "react";
import { conformPayment, createOrder } from "../../networkRequests/package";
import { toastError, toastSuccess } from "../../utils/common";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

export default function PaymentSuccess() {
  const history = useHistory();
  const queryPerms = queryString.parse(history.location.search);
  const _createOrder = async () => {
    let data = localStorage.package;
    data = JSON.parse(data);
    data.pickupTime = data.startTime;
    data.price = queryPerms.price;
    delete data.startTime;
    data["packageItems"] = data.packageItems.map((items) => {
      return {
        quantity: items.quantity,
        length: items.length,
        width: items.width,
        height: items.height,
        weight: items.weight,
        description: items.description,
      };
    });
    console.log(data);

    try {
      const paymentRes = await conformPayment(queryPerms.payment_intent);
      if (paymentRes.data.status !== "succeeded") {
        toastError("Payment failed");
        return history.push("/createOrder");
      }
      console.log(paymentRes);
      data.price = paymentRes.data.amount;
      const res = await createOrder(data);
      //console.log(res);
      localStorage.removeItem("package");
      toastSuccess("Order successfully placed");
      history.push("/myOrders");
      // setLoader(false);
    } catch (error) {
      toastError(error.data.message);
      console.log("error", error.data.message);
      // setLoader(false);
    }
  };
  useEffect(() => {
    _createOrder();
  });
  return <div>Payment Processing....</div>;
}
