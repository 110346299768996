export const modelType = {
  LOGIN: "Login",
  SIGNUP: "Signup",
  FORGOT_PASSWORD: "ForgotPassword",
  ADD_ADDRESS: "Add Address",
  EDIT_ADDRESS: "Edit Address",
  USER_DETAIL: "User Detail",
  CHANGE_PASSWORD: "Change Password",
  REFRESH_ADDRESS: "Refresh Address",
  VIEW_IMAGE: "view image",
  PAYMENT: "Payment",
  NULL: "Null",
};

//export const baseUrl = "http://localhost:5000";
//export const baseUrl = "http://3.141.68.7:5000";
export const baseUrl = "https://api.stayonsite.com.au";

export const webUrl = "https://bookings.stayonsite.com.au";
//export const webUrl = "http://localhost:3000";

export const awsBaseUrl =
  "https://sosbucket.s3.us-east-2.amazonaws.com/oderImage/";
export const PICKUP_TIME = [
  "07:00 am",
  "07:30 am",
  "08:00 am",
  "08:30 am",
  "09:00 am",
  "09:30 am",
  "10:00 am",
  "10:30 am",
  "11:00 am",
  "11:30 am",
  "12:00 pm",
  "12:30 pm",
  "01:00 pm",
  "01:30 pm",
  "02:00 pm",
  "02:30 pm",
  "03:00 pm",
];
