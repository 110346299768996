import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { editProfile } from "../../networkRequests/profile";
import { toastError, toastSuccess, loading } from "../../utils/common";

export default function UserDetail() {
  const { modelState, setModelState } = useModal();
  const user = JSON.parse(localStorage.user);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [lastName, setLastName] = useState(user.lastName);
  const [loader, setLoader] = useState(false);
  const [companyName, setCompanyName] = useState(user.companyName);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);

  const _editProfile = async (e) => {
    e.preventDefault();
    if (
      name == user.name &&
      email == user.email &&
      phoneNumber == user.phoneNumber &&
      lastName == user.lastName &&
      companyName == user.companyName
    ) {
      return setModelState(modelType.NULL);
    }
    if (!name) {
      return toastError("Please enter name");
    }

    if (!email) {
      return toastError("Please enter email");
    }
    if (phoneNumber) {
      if (
        isNaN(phoneNumber) ||
        phoneNumber.length > 10 ||
        phoneNumber.length < 10
      ) {
        return toastError("Please enter a valide phone number");
      }
    }
    try {
      setLoader(true);

      const res = await editProfile(
        name,
        email,
        lastName,
        phoneNumber,
        companyName
      );

      localStorage.setItem("user", JSON.stringify(res.data));
      setModelState(modelType.NULL);
      toastSuccess("Profile successfully updated");
      setLoader(false);
    } catch (error) {
      console.log(error.data.message);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  return (
    <Modal
      open={modelState == modelType.USER_DETAIL ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);
      }}
    >
      {loader ? loading : ""}
      <div className="session-wrap content-box-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
        <div className="session-content contact-form-wrap text-center card shadow-box">
          <div className="sec-heading-wrap flex-space-between">
            <h4 className="sec-title">Account Details</h4>
            {/* <i className="fas fa-times" /> */}
          </div>
          <div className="sec-content-wrap">
            <form className="form-wrap">
              <div className="form-group ">
                <label>First Name</label>
                <input
                  name="name-input"
                  className="form-control"
                  placeholder="name"
                  defaultValue={user.name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label> Last Name </label>
                <input
                  className="form-control"
                  type="email"
                  defaultValue={user.lastName}
                  placeholder=""
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label> Company Name </label>
                <input
                  className="form-control"
                  type="text"
                  value={companyName}
                  placeholder={`example "Microsoft"`}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label> Phone Number </label>
                <input
                  className="form-control"
                  type="text"
                  value={phoneNumber}
                  placeholder="1234567890"
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) setPhoneNumber(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label> Email Address </label>
                <input
                  className="form-control"
                  type="email"
                  defaultValue={user.email}
                  placeholder="example@gmail.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="btn-wrap text-align-center mt-4">
                <a
                  className="btn btn-primary"
                  href="javascript:void(0);"
                  onClick={_editProfile}
                >
                  Save Changes
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
