import React from "react";

const Home = React.lazy(() => import("./views/pages/Home"));
const Addresses = React.lazy(() => import("./views/pages/Addresses"));
const MyOrder = React.lazy(() => import("./views/pages/MyOders"));
const CreateOrder = React.lazy(() => import("./views/pages/CreateOrders"));
const OrderDetail = React.lazy(() => import("./views/pages/OrderDetail"));
const paymentSucess = React.lazy(() => import("./views/pages/PaymentSuccess"));

const routes = [
  { path: "/home", exact: true, name: "Home", component: Home },
  { path: "/addresses", exact: true, name: "Addresses", component: Addresses },
  { path: "/myOrders", exact: true, name: "My Orders", component: MyOrder },
  {
    path: "/createOrder",
    exact: true,
    name: "Create Orders",
    component: CreateOrder,
  },
  {
    path: "/createOrder/:id",
    exact: true,
    name: "Order Detail",
    component: OrderDetail,
  },
  // {
  //   path: "/createOrder/payment/success",
  //   exact: true,
  //   name: "Order Detail",
  //   component: paymentSucess,
  // },
];

export default routes;
