import React, { useEffect, useState, useLayoutEffect } from "react";
import { Modal } from "react-responsive-modal";
import { useAddress } from "../../context/addressState";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

//import "react-responsive-modal/styles.css";
import { modelType } from "../../utils/appConstants";
import { useModal } from "../../context/modalState";
import { addAddress, editAddress } from "../../networkRequests/profile";
import { toastError, toastSuccess, loading } from "../../utils/common";

export default function EditAddress() {
  const { address, setAddress } = useAddress();
  const { modelState, setModelState } = useModal();
  const [title, setTitle] = useState("");
  const [address1, setAddress1] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [loader, setLoader] = useState(false);
  const [lotNumber, setLotNumber] = useState("");
  const [fullAddress, setFullAddress] = useState("");

  const _editAddress = async (e) => {
    e.preventDefault();

    if (
      address.title == title &&
      address.address1 == address1 &&
      address.lotNumber === lotNumber &&
      fullAddress === address.fullAddress
    ) {
      return setModelState(modelType.NULL);
    }

    if (!title) {
      return toastError("Please enter title");
    }
    if (!longitude || !latitude) {
      return toastError("Please select address");
    }
    if (!address) {
      return toastError("Please enter full address");
    }
    try {
      setLoader(true);

      const res = await editAddress(
        address._id,
        title,
        address1,
        longitude,
        latitude,
        lotNumber,
        fullAddress
      );
      setLotNumber("");
      toastSuccess("Address Updated Successfully");
      setModelState(modelType.REFRESH_ADDRESS);
      setLoader(false);
    } catch (error) {
      console.log(error);
      toastError(error.data.message);
      setLoader(false);
    }
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress1(value);
    const coordinates = await getLatLng(results[0]);
    setLongitude(coordinates.lng);
    setLatitude(coordinates.lat);
  };

  useEffect(() => {
    setTitle(address.title);
    setAddress1(address.address);
    setLongitude(address.longitude);
    setLatitude(address.latitude);
    setLotNumber(address.lotNumber);
  }, [address]);
  return (
    <Modal
      open={modelState == modelType.EDIT_ADDRESS ? true : false}
      onClose={() => {
        setModelState(modelType.NULL);
        setAddress("");
      }}
    >
      <div className="session-wrap content-box-wrap signup-2-wrap shape-wrap d-flex align-items-center justify-content-center">
        <div className="session-content contact-form-wrap text-center card shadow-box">
          <div className="sec-heading-wrap flex-space-between">
            <h4 className="sec-title mb-0">Edit Address</h4>
            {/* <i className="fas fa-times" /> */}
          </div>

          <div className="sec-content-wrap">
            <form className="form-wrap">
              <div className="form-group">
                <label>Title/Name</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="text"
                  defaultValue={address.title}
                  placeholder="name"
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Full Address</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="text"
                  defaultValue={address.fullAddress}
                  placeholder="name"
                  onChange={(e) => {
                    setFullAddress(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <PlacesAutocomplete
                  value={address1}
                  onChange={(value) => {
                    setAddress1(value);
                    setLatitude("");
                    setLatitude("");
                  }}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "search places ...",
                          className: "form-control",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={suggestion.placeId}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>

              <div className="form-group">
                <label>Lot Number</label>
                <input
                  name="name-input"
                  className="form-control"
                  type="text"
                  value={lotNumber}
                  placeholder="123ABC"
                  onChange={(e) => {
                    setLotNumber(e.target.value);
                  }}
                />
              </div>

              <div className="btn-wrap text-align-center">
                <a
                  className="btn btn-primary"
                  href="javascript:void(0);"
                  onClick={_editAddress}
                >
                  Save Changes
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
