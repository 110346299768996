import qs from "qs";
import {
  makePostRequest,
  makePutRequest,
  makeGetRequest,
  makeDeleteRequest,
} from "./axiosBase";

export const editProfile = async (
  name,
  email,
  lastName,
  phoneNumber,
  companyName
) => {
  try {
    const data = {
      name,
      email,
      lastName,
      phoneNumber,
      companyName,
    };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/web/user/profile",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const data = {
      oldPassword,
      newPassword,
    };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/web/user/profile/changePassword",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addAddress = async (
  title,
  address,
  longitude,
  latitude,
  lotNumber,
  fullAddress
) => {
  try {
    const data = {
      title,
      fullAddress,
      longitude,
      latitude,
      lotNumber,
      address,
    };

    const config = { skipAuth: false };

    let res = await makePostRequest(
      "/web/user/profile/address",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAddresses = async () => {
  try {
    let res = await makeGetRequest("/web/user/profile/addresses");
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteAddress = async (addressId) => {
  try {
    const params = {
      addressId,
    };
    let res = await makeDeleteRequest("/web/user/profile/address", params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAddress = async (addressId) => {
  try {
    const params = {
      addressId,
    };

    let res = await makeGetRequest("/web/user/profile/address", params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editAddress = async (
  addressId,
  title,
  address,
  longitude,
  latitude,
  lotNumber,
  fullAddress
) => {
  try {
    const data = {
      addressId,
      title,
      address,
      longitude,
      latitude,
      lotNumber,
      fullAddress,
    };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/web/user/profile/address",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};

export const veriftInviteToken = async (token) => {
  try {
    const params = {
      token,
    };

    let res = await makeGetRequest("/auth/verifyInviteToken", params);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const defaultAddress = async (addressId) => {
  try {
    const data = {
      addressId,
    };
    const config = { skipAuth: false };

    let res = await makePutRequest(
      "/web/user/profile/address/default",
      qs.stringify(data),
      config,
      { crossDomain: true }
    );

    return res.data;
  } catch (error) {
    throw error;
  }
};
