import React from "react";

const Home = React.lazy(() => import("./views/pages/Home"));
const ForgotPassword = React.lazy(() => import("./views/pages/ForgotPassword"));
const InviteUser = React.lazy(() => import("./views/pages/InviteUser"));
const Login = React.lazy(() => import("./views/pages/Login"));

const routes = [
  { path: "/index", exact: true, name: "", component: Home },
  {
    path: "/forgotPassword/:token",
    exact: true,
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/invite/:token",
    exact: true,
    name: "Forgot Password",
    component: InviteUser,
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login,
  },
];

export default routes;
