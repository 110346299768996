import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { loading, toastError, toastSuccess } from "../../utils/common";
import {
  forgotPassword,
  verifyResetPasswordToken,
} from "../../networkRequests/forgotPassword";

export default function DriverResetPassword(props) {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loader, setLoader] = useState("");
  const [status, setStatus] = useState("");

  const _verifyResetPasswordToken = async () => {
    try {
      setLoader(true);
      const res = await verifyResetPasswordToken(props.match.params.token);
      console.log(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toastError("Link expired");
      setStatus("linkExpires");
      //  history.push("/index");
    }
  };

  const _onsubmit = async () => {
    if (!password) {
      return toastError("Please enter password");
    }
    if (!confirm) {
      return toastError("Please enter confirm password");
    }
    if (password !== confirm) {
      return toastError("Password and confirm password does not match");
    }
    try {
      const res = await forgotPassword(password, props.match.params.token);
      console.log(res);
      toastSuccess("Your password reset successfully.");
      setStatus("passwordChnaged");
      // return history.push("/index");
    } catch (error) {
      return toastError(error.response.data.message);
    }
  };

  useEffect(() => {
    _verifyResetPasswordToken();
  }, []);

  return (
    <div>
      {loader ? loading : ""}
      <div
        style={{
          maxWidth: 600,
          margin: "auto",
          boxShadow: "0 0  12px rgb(0, 0, 0, 0.3)",
          marginTop: 30,
        }}
      >
        <table
          className="email-template-wrap"
          style={{
            margin: "0 auto",
            maxWidth: 1000,
            width: "100%",
            padding: "40px 20px",
            fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
          }}
        >
          <tbody>
            <tr>
              <td className="email-text-content">
                <div
                  className="email-image"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // textAlign: "center",
                    paddingBottom: 40,
                    marginTop: 30,
                  }}
                  width="100%"
                >
                  <img
                    width={100}
                    height={56}
                    src="/assets/images/logo.png"
                    alt="logo-image"
                  />
                </div>
                <div
                  className="invoice-details"
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginBottom: 34,
                  }}
                >
                  {status === "" ? (
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        color: "#261134",
                      }}
                    >
                      Reset Password
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {status === "" ? (
                  <div>
                    <div
                      className="invoice-details"
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginBottom: 34,
                      }}
                    >
                      <input
                        type="password"
                        style={{
                          padding: "0.8 1rem",
                          paddingRight: "1rem",
                          paddingLeft: "1rem",
                          paddingLeft: "1.5rem",
                          paddingRight: "1.5rem",
                          height: "47px",
                          borderRadius: "5px",
                        }}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        placeholder="Password"
                      />
                    </div>

                    <div
                      className="invoice-details"
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginBottom: 34,
                      }}
                    >
                      <input
                        type="password"
                        style={{
                          padding: "0.8 1rem",
                          paddingRight: "1rem",
                          paddingLeft: "1rem",
                          paddingLeft: "1.5rem",
                          paddingRight: "1.5rem",
                          height: "47px",
                          borderRadius: "5px",
                        }}
                        placeholder="Comfirm Password"
                        value={confirm}
                        onChange={(e) => {
                          setConfirm(e.target.value);
                        }}
                      ></input>
                    </div>

                    <div
                      className="invoice-details"
                      style={{
                        display: "block",
                        textAlign: "center",
                        marginBottom: 34,
                      }}
                    >
                      <button
                        style={{
                          fontWeight: "bold",
                          border: "none",
                          padding: "12px 30px",
                          borderRadius: 10,
                          fontSize: 18,
                          backgroundColor: "#F95C19",
                          color: "#fff",
                        }}
                        onClick={() => {
                          _onsubmit();
                        }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {status === "linkExpires" ? (
                  <div
                    className="invoice-details"
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginBottom: 34,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        color: "red",
                      }}
                    >
                      Your password link has been expired, Please try again.
                    </span>
                  </div>
                ) : status === "passwordChnaged" ? (
                  <div
                    className="invoice-details"
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginBottom: 34,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        color: "green",
                      }}
                    >
                      Your password changed successfully.
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className="invoice-details"
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginBottom: 0,
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      color: "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    {/* Please ignore if this email has been sent to you by mistake,
                    we apologise for the inconvenience. */}
                  </span>
                </div>
                <table width="100%">
                  <tbody>
                    <tr valign="top"></tr>
                    <tr>
                      <td
                        className="invoice-details"
                        style={{
                          display: "block",
                          textAlign: "center",
                          marginBottom: 34,
                        }}
                      ></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
