import React from "react";
import { NavLink } from "react-router-dom";
import { useModal } from "../context/modalState";
import { modelType } from "../utils/appConstants";

export default function Header() {
  const { modelState, setModelState } = useModal();
  return (
    <header id="header-sec" className="header-v1 header-v2 header-transparent">
      <div className="header-wrap header-bg-color">
        <div className="container-fluid px-0">
          <div className="custom-container">
            <nav className="navbar navbar-expand-lg navbar-dark theme-navbar">
              <NavLink className="navbar-brand" to="/">
                <img
                  src="/assets/images/logo.png"
                  width={100}
                  height={56}
                  alt="header-logo"
                />
              </NavLink>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#main-header"
                aria-controls="main-header"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="navbar-collapse collapse" id="main-header">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="#process-div">
                      Process
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#service-div">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#aboutus-div">
                      About us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contactus-div">
                      Contact us
                    </a>
                  </li>
                  <li className="nav-item mt-lg-0 mt-3 center-align">
                    <a
                      className="btn btn-white-primary px-lg-3 px-3"
                      href="javascript:void(0);"
                      onClick={() => {
                        setModelState(modelType.LOGIN);
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li className="nav-item active menu-item-has-child d-none">
                    <a className="nav-link" href="javascript:void(0);">
                      About Us
                    </a>
                    <ul className="sub-menu list-unstyled">
                      <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0);">
                          About Us 1
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0);">
                          About Us 2
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0);">
                          About Us 3
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <div id="header-sticky" className="sticky-header">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="logo-wrap align-self-center">
              <NavLink to="/index" className="logo">
                <img
                  src="assets/images/sos-logo-white.png"
                  alt="sticky-logo"
                  className="img-responsive"
                  width={100}
                  height={56}
                />
              </NavLink>
            </div>
            <div className="align-self-center">
              <div className="header-menu pull-left">
                <nav id="sticky-nav" className="main-navigation theme-navbar">
                  <div className="primary-menu">
                    <ul className="navbar-nav mr-n3 mx-lg-auto px-0 flex-row">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          href="javascript:void(0);"
                          to="/index"
                        >
                          Process
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          href="javascript:void(0);"
                          to="/index"
                        >
                          Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          href="javascript:void(0);"
                          to="/index"
                        >
                          About us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          href="javascript:void(0);"
                          to="/index"
                        >
                          Contact us
                        </NavLink>
                      </li>
                      <li className="nav-item mt-lg-0 mt-3 center-align">
                        <a
                          className="btn btn-white-primary px-lg-3 px-3"
                          href="javascript:void(0);"
                          onClick={() => {
                            setModelState(modelType.LOGIN);
                          }}
                        >
                          Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </header>
  );
}
